<template>
    <section class="new_css">
        <el-row class="new_header">
            <el-col v-if="buttonList.length > 0"
                    :span="24"
                    class="organizetoolbar"
                    style="padding-bottom: 0px">
                <el-form :inline="true"
                         ref="selectForm"
                         :model="selectForm"
                         class="select_form">
                    <el-form-item label="名称:">
                        <el-input v-model="selectForm.name"></el-input>
                    </el-form-item>
                    <toolbar :buttonList="buttonList"
                             @callFunction="callFunction"
                             :buttonListmsg="buttonListmsg"></toolbar>
                </el-form>
            </el-col>
        </el-row>
        <div class="calc-height">
            <!--列表-->
            <el-table :data="users"
                      highlight-current-row
                      height="100%"
                      @current-change="selectCurrentRow"
                      @row-dblclick="handleShow"
                      class="new_table">
                <el-table-column prop="Name" label="名称"> </el-table-column>
                <el-table-column label="缴费基数" prop="BaseSalary"></el-table-column>
                <el-table-column label="公司缴费(实缴)" prop="FactorySalary"></el-table-column>
                <el-table-column label="公司缴费(应缴)" prop="FactorySalary02"></el-table-column>
                <el-table-column label="个人缴费(实缴)" prop="UserSalary"></el-table-column>
                <el-table-column label="个人缴费(应缴)" prop="UserSalary02"></el-table-column>
                <el-table-column label="是否能提取" prop="isExtract" width="100" filter-placement="bottom-end">
                    <template slot-scope="scope">
                        <el-tag :type="scope.row.isExtract === true ? 'success':'warning'" disable-transitions>{{scope.row.isExtract? `是`:`否`}}</el-tag>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 分页器 -->
        <el-pagination align="center"
                       @current-change="handleCurrentChange"
                       :current-page="currentPage"
                       :page-size="pageSize"
                       layout="prev, pager, next, jumper,total"
                       :page-count="total"
                       :total="totaldata">
        </el-pagination>
        <el-dialog :title="operation ? '新增' : '编辑'"
                   :visible.sync="SakaryVisible"
                   v-model="SakaryVisible"
                   :close-on-click-modal="false">
            <el-form ref="SakaryForm"
                     :model="SakaryForm"
                     class="demo-form-inline"
                     label-width="150px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="名称:" prop="Name" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
                            <el-input type="text" v-model="SakaryForm.Name"> </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="缴费基数:" prop="BaseSalary" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
                            <el-input type="text" v-model="SakaryForm.BaseSalary" @blur="inputMoney('BaseSalary')"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="公司缴费(实缴):" prop="FactorySalary" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
                            <el-input type="text" v-model="SakaryForm.FactorySalary" @blur="inputMoney('FactorySalary')" disabled=true></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="公司缴费(应缴):" prop="FactorySalary02" :rules="[ { required: true, message: '不能为空', trigger: 'blur' }, ]">
                            <el-input type="text" v-model="SakaryForm.FactorySalary02" @blur="inputMoney('FactorySalary02')"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="个人缴费(实缴):" prop="UserSalary" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
                            <el-input type="text" v-model="SakaryForm.UserSalary" @blur="inputMoney('UserSalary')" disabled=true></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="个人缴费(应缴):" prop="UserSalary02" :rules="[{ required: true, message: '不能为空', trigger: 'blur' }, ]">
                            <el-input type="text" v-model="SakaryForm.UserSalary02" @blur="inputMoney('UserSalary02')"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="是否能提取:" prop="isExtract">
                            <el-switch v-model="SakaryForm.isExtract" active-color="#67c23a" inactive-color="#e6a23c" active-text="是" inactive-text="否" > </el-switch>
                        </el-form-item>
                    </el-col>
                    
                </el-row>

                <el-row v-if="operation1">
                    <el-col :span="24" style="text-align: center">

                        <el-button type="primary" v-on:click="onSubmit('SakaryForm')">提交</el-button>

                    </el-col>
                </el-row>
            </el-form>
        </el-dialog>
    </section>
</template>
<script>
import util from "../../../util/date";
import * as validate from "../../../util/validate";
import {
  GetDicHouseFundsPageList,
        AddDicHouseFunds,
        UpdateDicHouseFunds,
} from "../../api/hr";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/newToolbar";
export default {
  components: { Toolbar },
  data() {
    return {
      loading: true,
      searchVal: "",
      selectForm: {
        name: "",
      },
      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      pageSize: 20, // 每页的数据条数
      page: 1,
      SakaryVisible: false,
      buttonListmsg: "",
      users: [],
      operation: false, // true:新增, false:编辑
      operation1: true,
      buttonList: [],
      SakaryForm: {
        Name: "",
        BaseSalary: "",
          FactorySalary: "",
          FactorySalary02: "",
          UserSalary: "",
          UserSalary02: "",
          isExtract: false,
      },
      
    };
  },
  methods: {
    selectCurrentRow(val) {
      this.currentRow = val;
    },
    callFunction(item) {
      this[item.Func].apply(this, item);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.page = val;
      this.getData(false);
    },
    //获取列表
    getData(isCurrent = true) {
      if (isCurrent) {
        this.page = 1;
      }
      let para = {
        page: this.page,
        size: this.pageSize,
        userName: this.selectForm.name,
      };
      console.log(para);
        GetDicHouseFundsPageList(para).then((res) => {
        console.log(res);
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
        this.users = res.data.response.data;
        this.loading = false;
      });
    },
    getButtonList2(routers) {
      let _this = this;
      routers.forEach((element) => {
        let path = this.$route.path.toLowerCase();
        if (element.path && element.path.toLowerCase() == path) {
          _this.buttonList = element.children;
          return;
        } else if (element.children) {
          _this.getButtonList(element.children);
        }
      });
    },
    handleEdit() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });
        return;
      }
      console.log(row);
      this.SakaryForm = Object.assign({}, row);
      (this.operation = false), (this.SakaryVisible = true);
      this.operation1 = true;
    },
    handleShow() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要查看的一行数据！",
          type: "error",
        });
        return;
      }
      console.log(row);
      this.SakaryForm = Object.assign({}, row);
      (this.operation = false), (this.SakaryVisible = true);
      this.operation1 = false;
    },
    handleAdd() {
      (this.SakaryForm = {
          Name: "",
          BaseSalary: "",
          FactorySalary: "0",
          FactorySalary02: "0",
          UserSalary: "0",
          UserSalary02: "0",
          isExtract: false,
      }),
        (this.operation = true),
        (this.SakaryVisible = true);
      this.operation1 = true;
    },
    inputMoney(name) {
      if (!validate.validateIsMoney(this.SakaryForm[name])) {
        this.$message({
          message: "请输入金额",
          type: "error",
          duration: 5000,
        });
        this.SakaryForm[name] = null;
        return;
      }
    },
    onSubmit(formName) {
      this.$confirm("确定要提交吗？", "提示", {}).then(() => {
        this.$refs[formName].validate((valid) => {
          let para = Object.assign({}, this.SakaryForm);
          if (this.operation) {
            //新增
              AddDicHouseFunds(para).then((res) => {
              if (res.data.success) {
                this.$message({
                  message: "添加成功",
                  type: "success",
                  duration: 5000,
                });
                this.SakaryVisible = false;
                this.getData();
              } else {
                this.$message({
                  message: "添加失败",
                  type: "error",
                  duration: 5000,
                });
              }
            });
          } else {
            //修改
              UpdateDicHouseFunds(para).then((res) => {
              if (res.data.success) {
                this.$message({
                  message: "修改成功",
                  type: "success",
                  duration: 5000,
                });
                this.SakaryVisible = false;
                this.getData(false);
              } else {
                this.$message({
                  message: "修改失败",
                  type: "error",
                  duration: 5000,
                });
              }
            });
          }
        });
      });
    },
  },
  mounted() {
    this.getData();
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    let buttons = window.localStorage.buttList
      ? JSON.parse(window.localStorage.buttList)
      : [];
    this.buttonList = getButtonList(this.$route.path, routers, buttons);
  },
};
</script>
<style scoped>
</style>

